import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class ComplimentaryResources extends React.Component {
	render() {
		const siteTitle = 'Complimentary Resources'
		const siteDescription = this.props.data.site.siteMetadata.description

		return (
			<Layout location={this.props.location} title={siteTitle}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3>Complimentary Resources</h3>
								<br />
								<p>
									<iframe
										src="https://player.vimeo.com/video/214843285"
										webkitallowfullscreen=""
										mozallowfullscreen=""
										allowfullscreen=""
										width="100%"
										height="480"
										frameborder="0"
										title="Saturday Morning with Luke Grant"
									></iframe>
								</p>
								<p>
									<strong>
										On Saturday morning, 18 February 2017, David was interviewed
										on the Macquarie Radio Network’s Saturday Morning with Luke
										Grant about changes to the CGT discount. The interview
										starts at about 1hr 22mins (1:22) in.
									</strong>
								</p>
								<hr />
								<p>
									<iframe
										src="https://player.vimeo.com/video/214843748"
										webkitallowfullscreen=""
										mozallowfullscreen=""
										allowfullscreen=""
										width="100%"
										height="360"
										frameborder="0"
										title="David Shaw ABC News"
									></iframe>
								</p>
								<p>
									<strong>David Shaw, CEO, on ABC News 23 July 2015</strong>
								</p>
								<hr />
								<p>
									<iframe
										src="https://player.vimeo.com/video/214843852"
										webkitallowfullscreen=""
										mozallowfullscreen=""
										allowfullscreen=""
										width="100%"
										height="352"
										frameborder="0"
										title="Tax Variation Webinar Presentation"
									></iframe>
								</p>
								<p>
									<strong>Tax Variation Webinar Presentation</strong>
								</p>
								<hr />
								<p>
									<iframe
										src="https://player.vimeo.com/video/214843974"
										webkitallowfullscreen=""
										mozallowfullscreen=""
										allowfullscreen=""
										width="100%"
										height="352"
										frameborder="0"
										title="Tax Variation Webinar Presentation"
									></iframe>
								</p>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default ComplimentaryResources

export const ComplimentaryResourcesPageQuery = graphql`
	query ComplimentaryResourcesPageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
